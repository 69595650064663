<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con">
            <div class="mat-info-con even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Committed Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committedYear"
                            v-model="committedYr" @change="filterHandler()">
                            <option v-for="year in committedYear" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="plant" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                            :value="plantSelectedInAsmtHome" @change="plantChanged($event); filterHandler();">
                            <option v-for=" plant  in  plantList " v-bind:key=" plant.plantId " :value=" plant.plantId ">
                                {{ plant.plantName }}, {{ plant.regionCode }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="workcenter" class="form-label select-lable lable-text"> Work Center </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="workcenterId"
                            :value=" prevSelectedWorkCenter " @change=" workcenterChanged($event); filterHandler(); ">
                            <option v-for=" wc  in  workCenterList " v-bind:key=" wc.workcenterId " :value=" wc.workcenterId ">
                                {{ wc.workcenterName }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="pillar" class="form-label select-lable lable-text"> Pillar </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="pillarId"
                            :value=" pillarPrevSelected " @change=" pillarChanged($event); filterHandler(); ">
                            <option v-for=" plr  in  pillarList " v-bind:key=" plr.pillarId " :value=" plr.pillarId ">
                                {{ plr.pillarCode }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div style="display: flex; justify-content: space-between; padding: 0 2rem">
                <div class="even-space-vertical-align">
                    <div class="ma-status">Status :</div>
                    <div class="mat-status-con">
                        <img alt="on Track" src="../../../assets/blue-tick.svg" />
                        <span class="ma-status-able mr-lf-3" style="vertical-align: middle">On Track</span>
                    </div>
                    <div class="mat-status-con">
                        <img alt="off track" src="../../../assets/maturity-not-cmpl.svg" />
                        <span class="ma-status-able mr-lf-3" style="vertical-align: middle">Off Track</span>
                    </div>
                </div>
                <div class="edit-button-div">
                    <b-button class="asmt-bt button-sm" style="padding: 0.25rem 1rem; margin-left: 1rem" pill
                        :disabled=" noMachineDataFound || isTargetSetandApproved || loggedInUserData.plantId !== plantId.plantId || committedYr !== new Date().getFullYear() "
                        variant="outline-primary" v-if="
                            $oidc.userProfile.roles.includes(roles.PLANTSUBPILLARLEADER) ||
                                $oidc.userProfile.roles.includes(roles.PLANTPOLEADER) ||
                                $oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER)
                        " @click=" $router.push('/current-score-setting') ">
                        Edit
                    </b-button>
                    <b-button class="asmt-bt button-sm" style="padding: 0.25rem 1rem; margin-left: 1rem" pill
                        variant="outline-primary" v-if="
                            $oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) ||
                                $oidc.userProfile.roles.includes(roles.PLANTSUBPILLARLEADER) ||
                                $oidc.userProfile.roles.includes(roles.PLANTPOLEADER)
                        " @click=" $router.push('/machine-set-target') "
                        :disabled=" loggedInUserData.plantId !== plantId.plantId || disableTargetbtn ">
                        Set Target
                    </b-button>
                    <b-button class="asmt-bt button-sm" style="padding: 0.25rem 1rem; margin-left: 1rem" pill
                        variant="outline-primary" :disabled=" loggedInUserData.plantId !== plantId.plantId " v-if="
                            $oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) ||
                                $oidc.userProfile.roles.includes(roles.PLANTSUBPILLARLEADER) ||
                                $oidc.userProfile.roles.includes(roles.PLANTPOLEADER)
                        " @click=" $router.push('/machine-initiate-approval') ">
                        Initiate Approvals
                    </b-button>
                    <b-button class="asmt-bt button-sm" style="padding: 0.25rem 1rem; margin-left: 1rem" pill
                        :disabled=" loggedInUserData.plantId !== plantId.plantId " variant="outline-primary"
                        @click=" $router.push('/machine-targetset-approval') "
                        v-if=" $oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) || $oidc.userProfile.roles.includes(roles.PLANTPOLEADER) ">
                        Mark Approvals
                    </b-button>
                </div>
            </div>
            <div style="display: flex; flex-wrap: wrap">
                <div class="machine-action-tracker">
                    <b-table-simple responsive outlined id="machine-action-tracker" sticky-header
                        class="machine-action-table scroll gn-table-b">
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th v-for=" header  in  machineDetailHeader " @click=" sortCol(header) "
                                    :aria-sort=" header.sortable ? header.sortByAsc : 'neutral' " :key=" header.key ">
                                    {{ header.label }}
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody v-if=" machineDetail && machineDetail.length !== 0 ">
                            <b-tr v-for="( rowData, index ) in  machineDetail " :key=" index ">
                                <b-td v-if=" !(index % 3) " rowspan="3" class="machine-action-table-machine">
                                    {{ rowData.machineName }}
                                </b-td>
                                <b-td v-if=" !(index % 3) " rowspan="3" class="machine-action-table-machine">
                                    {{ rowData.machineStatus }}
                                </b-td>
                                <b-td class="machine-action-tracker-label">{{ rowData.labels }}</b-td>
                                <b-td>
                                    <div class="display-flex-center">
                                        <div v-if=" rowData.jan.hasOwnProperty('status') "
                                            :class=" rowData.jan.status + ' display-flex-center' ">
                                            {{ rowData.jan.value }}
                                        </div>
                                        <div v-else>{{ rowData.jan }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="display-flex-center">
                                        <div v-if=" rowData.feb.hasOwnProperty('status') "
                                            :class=" rowData.feb.status + ' display-flex-center' ">
                                            {{ rowData.feb.value }}
                                        </div>
                                        <div v-else>{{ rowData.feb }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="display-flex-center">
                                        <div v-if=" rowData.mar.hasOwnProperty('status') "
                                            :class=" rowData.mar.status + ' display-flex-center' ">
                                            {{ rowData.mar.value }}
                                        </div>
                                        <div v-else>{{ rowData.mar }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="display-flex-center">
                                        <div v-if=" rowData.apr.hasOwnProperty('status') "
                                            :class=" rowData.apr.status + ' display-flex-center' ">
                                            {{ rowData.apr.value }}
                                        </div>
                                        <div v-else>{{ rowData.apr }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="display-flex-center">
                                        <div v-if=" rowData.may.hasOwnProperty('status') "
                                            :class=" rowData.may.status + ' display-flex-center' ">
                                            {{ rowData.may.value }}
                                        </div>
                                        <div v-else>{{ rowData.may }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="display-flex-center">
                                        <div v-if=" rowData.jun.hasOwnProperty('status') "
                                            :class=" rowData.jun.status + ' display-flex-center' ">
                                            {{ rowData.jun.value }}
                                        </div>
                                        <div v-else>{{ rowData.jun }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="display-flex-center">
                                        <div v-if=" rowData.jul.hasOwnProperty('status') "
                                            :class=" rowData.jul.status + ' display-flex-center' ">
                                            {{ rowData.jul.value }}
                                        </div>
                                        <div v-else>{{ rowData.jul }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="display-flex-center">
                                        <div v-if=" rowData.aug.hasOwnProperty('status') "
                                            :class=" rowData.aug.status + ' display-flex-center' ">
                                            {{ rowData.aug.value }}
                                        </div>
                                        <div v-else>{{ rowData.aug }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="display-flex-center">
                                        <div v-if=" rowData.sep.hasOwnProperty('status') "
                                            :class=" rowData.sep.status + ' display-flex-center' ">
                                            {{ rowData.sep.value }}
                                        </div>
                                        <div v-else>{{ rowData.sep }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="display-flex-center">
                                        <div v-if=" rowData.oct.hasOwnProperty('status') "
                                            :class=" rowData.oct.status + ' display-flex-center' ">
                                            {{ rowData.oct.value }}
                                        </div>
                                        <div v-else>{{ rowData.oct }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="display-flex-center">
                                        <div v-if=" rowData.nov.hasOwnProperty('status') "
                                            :class=" rowData.nov.status + ' display-flex-center' ">
                                            {{ rowData.nov.value }}
                                        </div>
                                        <div v-else>{{ rowData.nov }}</div>
                                    </div>
                                </b-td>
                                <b-td>
                                    <div class="display-flex-center">
                                        <div v-if=" rowData.dec.hasOwnProperty('status') "
                                            :class=" rowData.dec.status + ' display-flex-center' ">
                                            {{ rowData.dec.value }}
                                        </div>
                                        <div v-else>{{ rowData.dec }}</div>
                                    </div>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tbody v-if=" machineDetail && machineDetail.length === 0 ">
                            <tr>
                                <td class="text-center" style="height: 380px" rowspan="6"
                                    :colspan=" machineDetailHeader.length ">
                                    <img alt="no data" src="@/assets/No_Data.svg" />
                                    <div class="ed-date">No Data Found</div>
                                </td>
                            </tr>
                        </b-tbody>
                        <b-tfoot> </b-tfoot>
                    </b-table-simple>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import { mapGetters } from "vuex";
import Roles from "@/common/constants/roles";
import moment from "moment";

export default {
    name: "ActionTrackerPillarWise",
    data() {
        return {
            resData: [],
            pilladDtl: [],
            pilladDtl1: [],
            showLoader: true,
            plantList: [],
            pillarList: [],
            workCenterList: [],
            disableTargetbtn: false,
            plantId: 0,
            committedYear: [],
            machineScore: [],
            committedYr: new Date().getFullYear(),
            noMachineDataFound: false,
            isTargetSetandApproved: true,
            machineDetailHeader: [
                {
                    key: "machineName",
                    label: "Machine",
                    sortable: false
                },
                {
                    key: "machineStatus",
                    label: "Classification",
                    sortable: true,
                    sortByAsc: "none"
                },
                {
                    key: "labels",
                    sortable: false
                },
                {
                    key: "jan",
                    label: "Jan",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "feb",
                    label: "Feb",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "mar",
                    label: "Mar",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "apr",
                    label: "Apr",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "may",
                    label: "May",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "jun",
                    label: "Jun",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "jul",
                    label: "Jul",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "aug",
                    label: "Aug",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "sep",
                    label: "Sep",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oct",
                    label: "Oct",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "nov",
                    label: "Nov",
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "dec",
                    label: "Dec",
                    sortable: false,
                    variant: "success"
                }
            ],
            machineDetail: [],
            loggedInRole: "",
            roles: Roles
        };
    },
    components: {
        Breadcrumb,
        Loader
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 4,
            subMenu: 3
        });
        this.$store.dispatch("breadcrumb", {
            title: "Plant Optimization - Step Audit by Pillar",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Machine Replication Step Audit", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        this.getCommittedYear();
        this.loggedInRole = this.$oidc.userProfile.roles[0];
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "prevSelectedWorkCenter", "loggedInUserData", "pillarPrevSelected"])
    },
    methods: {
        sortCol(header) {
            if (header.sortable) {
                let temp = [];
                const op1 = header.sortByAsc === "descending" ? 1 : -1;
                const op2 = header.sortByAsc === "descending" ? -1 : 1;
                this.resData.data.sort((a, b) => {
                    if (a.machineStatus < b.machineStatus) return op1;
                    else return a.machineStatus === b.machineStatus ? 0 : op2;
                });

                this.resData.data.forEach((pillarData) => {
                    if (pillarData.isApproved) this.isTargetSetandApproved = false;
                    else this.isTargetSetandApproved = true;
                    if (Object.hasOwnProperty.call(pillarData, "isApproved") && pillarData.isApproved)
                        temp.push({
                            machineName: pillarData.machineName,
                            machineStatus: pillarData.machineStatus,
                            labels: "Target",
                            ...pillarData.target
                        });
                    else {
                        let tempTarget = {};
                        for (const month in pillarData.current) {
                            if (Object.hasOwnProperty.call(pillarData.current, month)) {
                                tempTarget[month] = 0;
                            }
                        }
                        temp.push({
                            machineName: pillarData.machineName,
                            machineStatus: pillarData.machineStatus,
                            labels: "Target",
                            ...tempTarget
                        });
                    }
                    let tempCurrent = {};
                    let flag = true;
                    for (const month in pillarData.current) {
                        if (Object.hasOwnProperty.call(pillarData.current, month)) {
                            const current = pillarData.current[month];
                            const target = pillarData.target[month];
                            const currentMonth = moment(new Date()).format("MMM").toLowerCase();

                            if (pillarData.isApproved && (month === currentMonth || flag)) {
                                flag = false;
                                tempCurrent[month] = { value: current, status: current < target ? "offtrack" : "ontrack" };
                            } else tempCurrent[month] = { value: current, status: "default" };
                        }
                    }
                    temp.push({
                        labels: "Current",
                        ...tempCurrent
                    });
                    temp.push({
                        labels: "Audit Score",
                        ...pillarData.auditScore
                    });
                });

                this.machineDetail = temp;
                if (this.machineDetail.length == 0) this.noMachineDataFound = true;
                else this.noMachineDataFound = false;

                let pos = this.machineDetailHeader.findIndex((el) => el.sortable);
                this.machineDetailHeader[pos].sortByAsc = header.sortByAsc === "descending" ? "ascending" : "descending";
            }
        },
        getCommittedYear() {
            ApiCalls.httpCallDetails(Api.MRCOMMITTEDYEAR, "get").then((data) => {
                this.committedYear = data.data;
                this.committedYr = new Date().getFullYear();
                this.getPlantDetails();
            });
        },
        getPlantDetails() {
            ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                let index = 0;
                if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
                else if (this.loggedInUserData.plantId) {
                    let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                } else if (this.loggedInUserData.regionName) {
                    let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                }
                this.plantId = this.plantList[index];
                this.$store.dispatch("plantSelectedInAsmtHome", plantId);
                this.getWorkCenterDetails();
                this.getPillarDetails();
            });
        },
        plantChanged(event) {
            console.log("plantChanged");
            this.showLoader = true;
            this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
            this.$store.dispatch("prevSelectedWorkCenter", 0);
            this.getWorkCenterDetails();
        },
        getWorkCenterDetails() {
            ApiCalls.httpCallDetails(Api.MRWORKCENTERALL, "post", { regionId: this.plantId.regionId }).then((data) => {
                this.workCenterList = data.data;
                let index = 0;
                if (this.prevSelectedWorkCenter) index = this.workCenterList.findIndex((el) => el.workcenterId == this.prevSelectedWorkCenter);

                this.wcId = this.workCenterList[index].workcenterId;
                this.$store.dispatch("prevSelectedWorkCenter", this.wcId);
                this.getPillarDetails();
            });
        },
        workcenterChanged(event) {
            this.showLoader = true;
            this.$store.dispatch("prevSelectedWorkCenter", event.target.value);
            let changedWcId = this.workCenterList.find((el) => el.workcenterId == event.target.value);
            this.wcId = changedWcId.workcenterId;
            this.getPillarDetails();
        },

        getPillarDetails() {
            ApiCalls.httpCallDetails(Api.MRPILLARDETAILS, "get").then((data) => {
                this.pillarList = data.data;
                let index = 0;
                if (this.pillarPrevSelected) index = this.pillarList.findIndex((el) => el.pillarId == this.pillarPrevSelected);
                this.pilrId = this.pillarList[index].pillarId;
                this.$store.dispatch("pillarPrevSelected", this.pilrId);
                this.filterHandler();
            });
        },
        pillarChanged(event) {
            this.showLoader = true;
            this.$store.dispatch("pillarPrevSelected", event.target.value);

            this.filterHandler();
        },

        filterHandler() {
            let machinewisePayload = {
                plantId: this.plantId.plantId,
                workcenterId: this.wcId,
                pillarId: this.pillarPrevSelected,
                committedYear: this.committedYr
            };
            this.showLoader = true;

            ApiCalls.httpCallDetails(Api.MRMACHINESCORE, "post", machinewisePayload).then((data) => {
                this.resData = data;
                if (this.resData.data.length != 0) {
                    this.disableTargetbtn = false;
                } else {
                    this.disableTargetbtn = true;
                }

                this.showLoader = false;
                let temp = [];
                this.resData.data.forEach((pillarData) => {
                    if (pillarData.isApproved) this.isTargetSetandApproved = false;
                    else this.isTargetSetandApproved = true;
                    if (Object.hasOwnProperty.call(pillarData, "isApproved") && pillarData.isApproved)
                        temp.push({
                            machineName: pillarData.machineName,
                            machineStatus: pillarData.machineStatus,
                            labels: "Target",
                            ...pillarData.target
                        });
                    else {
                        let tempTarget = {};
                        for (const month in pillarData.current) {
                            if (Object.hasOwnProperty.call(pillarData.current, month)) {
                                tempTarget[month] = 0;
                            }
                        }
                        temp.push({
                            machineName: pillarData.machineName,
                            machineStatus: pillarData.machineStatus,
                            labels: "Target",
                            ...tempTarget
                        });
                    }
                    let tempCurrent = {};
                    let flag = true;
                    for (const month in pillarData.current) {
                        if (Object.hasOwnProperty.call(pillarData.current, month)) {
                            const current = pillarData.current[month];
                            const target = pillarData.target[month];
                            const currentMonth = moment(new Date()).format("MMM").toLowerCase();
                            if (pillarData.isApproved && (month === currentMonth || flag)) {
                                flag = !(month === currentMonth)
                                tempCurrent[month] = { value: current, status: current < target ? "offtrack" : "ontrack" };
                            } else tempCurrent[month] = { value: current, status: "default" };
                        }
                    }
                    temp.push({
                        labels: "Current",
                        ...tempCurrent
                    });
                    temp.push({
                        labels: "Audit Score",
                        ...pillarData.auditScore
                    });
                });

                this.machineDetail = temp;
                if (this.machineDetail.length == 0) this.noMachineDataFound = true;
                else this.noMachineDataFound = false;
            });
        }
    }
};
</script>

<style scoped>
.graph-div {
    width: 25%;
    padding: 0 1%;
}

.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.plant-dd {
    width: 20.5%;
}

.asmt-bt:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.asmt-bt:hover {
    border-color: #869ed8 !important;
    color: #274ba7 !important;
    background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.asmt-bt {
    border-color: #869ed8 !important;
    background: #eff3ff 0% 0% no-repeat padding-box !important;
    color: #274ba7 !important;
}

.mat-status-con {
    margin-left: 2rem;
    display: flex;
    align-items: center;
}

.machine-action-tracker {
    margin-top: 2rem;
    width: 100%;
}

#machine-action-tracker th.table-success {
    background-color: #f4f6fd;
}

#machine-action-tracker th:first-child,
#machine-action-tracker th:nth-child(2) {
    background-color: white !important;
    font-weight: medium;
}

#machine-action-tracker th:nth-child(2) {
    cursor: pointer;
}

#machine-action-tracker tr:nth-child(3n-2) td:first-child,
#machine-action-tracker tr:nth-child(3n-2) td:nth-child(2) {
    border-bottom: 1px solid #eae6e6 !important;
}

#machine-action-tracker tr:nth-child(3n) td {
    border-bottom: 1px solid #eae6e6 !important;
}

.machine-action-tracker-label {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
}

.machine-action-table {
    max-height: 37rem !important;
}

.machine-action-table thead th {
    background-color: #f7f9ff !important;
}

.machine-action-table thead th,
.machine-action-table td {
    text-align: center !important;
    vertical-align: middle !important;
}

#machine-action-tracker>.machine-action-table th,
td {
    border: 0px !important;
    border-right: 1px solid #eae6e6 !important;
    border-left: 1px solid #eae6e6 !important;
}

.machine-action-table-machine {
    text-align: left !important;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

.machine-action-table th:first-child {
    width: 15%;
}

.machine-action-table td,
.machine-action-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.machine-action-table td tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table tbody tr:nth-child(even) {
    background-color: #fafafa !important;
}

.machine-action-table .table-light,
.machine-action-table .table-light>td,
.machine-action-table .table-light>th {
    background-color: #f0f0f0 !important;
}

.machine-action-table body tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-action-table th.table-success {
    background-color: #f4f6fd;
}

.machine-action-table th:first-child,
.machine-action-table td:first-child {
    background-color: transparent !important;
}

.machine-action-table td.table-success {
    background-color: transparent;
}

.machine-action-table tr {
    height: 5rem;
}

.machine-action-table thead tr {
    border-bottom: 2px solid #dddddd !important;
}

.display-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.ontrack {
    width: 2rem;
    background: #3a5824;
    margin: 0 25%;
    text-align: center !important;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.offtrack {
    width: 2rem;
    background: #c11a3b;
    margin: 0 25%;
    height: 2rem;
    text-align: center !important;
    border-radius: 10rem;
    color: #fff !important;
}

.default {
    width: 2rem;
    background: transparent;
    margin: 0 25%;
    height: 2rem;
    text-align: center !important;
    border-radius: 10rem;
    color: #333 !important;
}

.row-month {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
